<template>
  <section class="content">
    <div class="text">
      <h1>Thank you</h1>
      <h2>Your payment was successful and your booking is secured</h2>
      <p>Please check your email for a payment reciept.</p>
    </div>
  </section>
</template>

<script>
export default {
  created() {}
}
</script>
